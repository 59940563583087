<template>
  <div class="report-root-container">
    <div class="page-title">在线报告</div>
    <slide-tabbar style="margin-top:20px;" :tabs="tabs"></slide-tabbar>
    <div class="report-month-container">
      <div style="margin-top:60px;margin-bottom:40px;">2020年6月</div>
      <div class="report-item-container">
        <img :src="icons.reportPlaceholder" style="margin: 20px 50px;" />
        <div>
          <div style="font-size: 18px;font-weight: 500;color: #000000;">月度用工支出分析</div>
          <div style="font-size: 12px;color: #626262;">服务提供商:游隼资本有限公司</div>
          <div
            style="font-size: 13px;color: #000000;margin-top: 15px;"
          >这项服务可以将多个公司的支出卡片进行加总，从而得出您旗下所有公司的支出情况，支出情况可以以时间维度进行查询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../common/icon";
import SlideTabbar from "../../components/SlideTabbar";
export default {
  components: {
    "slide-tabbar": SlideTabbar,
  },
  data() {
    return {
      icons: icon,
      tabs: [{ name: "支出分析" }],
    };
  },
};
</script>

<style scoped>
.report-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  background-color: #f9f9f9;
}
.report-options-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  color: #000000;
  font-size: 13px;
  font-weight: 500;
}
.report-option-item {
  cursor: pointer;
  margin-right: 50px;
}
.report-item-container {
  width: 780px;
  height: 160px;
  background: #f7fcff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-self: space-between;
  align-items: center;
}
</style>